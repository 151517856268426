export const Pricing = (props) => {
  return (
    <div id='pricing' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Pricing List</h2>
          <p>
             Disclaimer: prices are meant as a rough estimate only
          </p>
              </div>

        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                    {' '}                    
                    <a href={d.pricelistwoman} target="_blank" rel="noreferrer">
                        <i className={d.icon}></i>
                    </a>                    
                  <div className='pricing-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
