import { Image } from "./image";

export const Services = (props) => {
    return (
        <div id='services' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Our Services</h2>
                    <p>
                        We offer an array of services that satisfy all our clients
                    </p>
                </div>
              
                <div className='grid'>                  

                        {props.data ? props.data.map((d, i) => (
                            <div key={`${d.title}-${i}`}>
                                <Image title={d.title} smallImage={d.smallImage} description={d.description}/>
                            </div>

                            ))
                            : 'Loading...'}
                    </div>

        

            </div>
        </div>
    )
}
