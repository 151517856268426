import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Pricing } from "./components/pricing";
import { Services } from "./components/services";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
speed: 1000,
speedAsDuration: true,
});

const App = () => {
const [landingPageData, setLandingPageData] = useState({});
useEffect(() => {
setLandingPageData(JsonData);
}, []);

return (
<div>
    <Navigation />
        <Header data={landingPageData.Header} />
        <Services data={landingPageData.Services} />
        <Pricing data={landingPageData.Pricing} />
        {/*<Features data={landingPageData.Features} />*/}
        <About data={landingPageData.About} />         
        {/*<Testimonials data={landingPageData.Testimonials} />*/}
        {/*<Team data={landingPageData.Team} />*/}
    <Contact data={landingPageData.Contact} />
</div>
);
};

export default App;
