import { useState, useEffect } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
    const [{ name, email, message }, setState] = useState(initialState)

    const [ statusMessage, setStatusMessage] = useState("")
    const [ isError, setError] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const clearState = () => setState({ ...initialState })

    const handleSubmit = (e) => {
    e.preventDefault()
        console.log(name, email, message)
    emailjs.sendForm('service_irssevg', 'template_ip7kig6', e.target, '69vZXZWPdkbnXwOgr')
        .then((result) => {
            console.log(result.text)
            clearState()
            setStatusMessage("Your email was sent successfully");
            setError(false);
            e.target.reset();
        },  (error) => {
            console.log(error.text)
            setStatusMessage(`${error.text}`);
            setError(true);
        });
    
    }

    useEffect(() => {
        if (statusMessage !== '') {
            setTimeout(() => {
                setStatusMessage('');
            }, 6000);
        }
    }, [statusMessage]);
  

  return (
      <div>
          <div id='contact'>
              <div className='container'>
                  <div className='col-md-8'>
                      <div className='row'>
                          <div className='section-title'>
                              <h2>Get In Touch</h2>
                              <p>
                                  Not sure or have questions before you decide?<br></br>
                                  <br></br>
                                  We'd love to hear from you. Send us a message with details on your request, or any questions you have on our services. We will get back to you as soon as possible with an answer.
                              </p>                              
                          </div>
                          <div>
                              <p className={isError ? 'alert-message' :'success-message'}>{statusMessage}</p>
                          </div>
                          <form name='sentMessage' validate onSubmit={handleSubmit}>
                              <div className='row'>
                                  <div className='col-md-6'>
                                      <div className='form-group'>
                                          <input
                                              type='text'
                                              id='name'
                                              name='name'
                                              className='form-control'
                                              placeholder='Name'
                                              required
                                              onChange={handleChange}
                                          />
                                          <p className='help-block text-danger'></p>
                                      </div>
                                  </div>
                                  <div className='col-md-6'>
                                      <div className='form-group'>
                                          <input
                                              type='email'
                                              id='email'
                                              name='email'
                                              className='form-control'
                                              placeholder='Email'
                                              required
                                              onChange={handleChange}
                                          />
                                          <p className='help-block text-danger'></p>
                                      </div>
                                  </div>
                              </div>
                              <div className='form-group'>
                                  <textarea
                                      name='message'
                                      id='message'
                                      className='form-control'
                                      rows='4'
                                      placeholder='Message'
                                      required
                                      onChange={handleChange}
                                  ></textarea>
                                  <p className='help-block text-danger'></p>
                              </div>
                              <div id='success'></div>
                              <button type='submit' className='btn btn-custom btn-lg'>
                                  Send Message
                              </button>{/*TODO: uncomment for production*/}
                          </form>
                      </div>
                  </div>
                  <div className='col-md-3 col-md-offset-1 contact-info'>
                      <div className='contact-item'>
                          <h3>Contact Info</h3>
                          <p>
                              <span>
                                  <i className='fa fa-map-marker'></i> Address
                              </span>
                              {props.data ? props.data.address : 'loading'}
                          </p>
                      </div>
                      <div className='contact-item'>
                          <p>
                              <span>
                                  <i className='fa fa-phone'></i> Phone
                              </span>{' '}
                              {props.data ? props.data.phone : 'loading'}
                          </p>
                      </div>
                      <div className='contact-item'>
                          <p>
                              <span>
                                  <i className='fa fa-envelope-o'></i> Email
                              </span>{' '}
                              {props.data ? props.data.email : 'loading'}
                          </p>
                      </div>
                  </div>
                  <div className='col-md-12'>
                      <div className='row'>
                          <div className='social'>
                              <ul>
                                  <li>
                                      <a href={props.data ? props.data.facebook : '/'}>
                                          <i className='fa fa-facebook'></i>
                                      </a>
                                  </li>
                                  <li>
                                      <a href={props.data ? props.data.twitter : '/'}>
                                          <i className='fa fa-twitter'></i>
                                      </a>
                                  </li>
                                  <li>
                                      <a href={props.data ? props.data.youtube : '/'}>
                                          <i className='fa fa-youtube'></i>
                                      </a>
                                  </li>
                              </ul>
                          </div>{/*TODO: uncomment for production*/}
                      </div>
                  </div>
              </div>
          </div>
          <div id='footer'>
              <div className='container text-center'>
                  <p>
                      &copy; 2022 Design by{' '}
                      <a href='http://www.SeamstressBird.com' rel='nofollow'>
                          SeamstressBird
                      </a>
                  </p>
              </div>
          </div>
      </div>
  )
}
