export const Image = ({ title, smallImage, description }) => {
    return (
        //<div className='portfolio-item'>
        //    <div className='hover-bg'>
        //        {' '}

        //            <div className='hover-text'>
        //                <h4>{title}</h4>
        //            </div>
        //            <img
        //                src={smallImage}
        //                className='img-responsive'
        //                alt={title}
        //            />{' '}

        //    </div>
        //</div>

 
            <figure class="effect-layla">
                <img src={smallImage} alt={title} />
                <figcaption>
                    <h3>{title}</h3>
                    <p>{description}</p>
                    {/*<a href="#">View more</a>*/}
                </figcaption>
            </figure>

    )
}